import "esbuild-css-modules-plugin-ns-css:src/components/HeroBanners/MainPageBannerV1/MainPageBannerV1.module.css";
export const banner = "MainPageBannerV1-module__banner_S-IDLa__001";
export const bannerContent = "MainPageBannerV1-module__banner-content_S-IDLa__001";
export const ctaButton = "MainPageBannerV1-module__cta-button_S-IDLa__001";

export default {
  "banner": banner,
  "bannerContent": bannerContent,
  "ctaButton": ctaButton
};
      