/**
 * SPA Page 
 */

import React from "react";
import { Title } from "@cenume/shared-fe"
import { Outlet, Route } from 'react-router-dom';
import { ManageAccountPage } from "./ManageAccountPage/ManageAccountPage";
import { AccountDisplayPage } from "./AccountDisplayPage/AccountDisplayPage";
import AuthWrapper from "../../../components/Utility/AuthWrapper/AuthWrapper";

export const ProfileRoot = ()=>{
  return (<AuthWrapper accessId="profile.root">
    <Title>Profile</Title>
    <Outlet />
    <></>
  </AuthWrapper>)
};

export const ProfileRoutes = [
  {
    path: "manage",
    Component: ManageAccountPage
  },
  {
    path: "display",
    Component: AccountDisplayPage
  },
];