/**
 * Render SPA
 */

import React from 'react';
import { createBrowserRouter, RouteObject, RouterProvider } from 'react-router-dom';
import { Explore } from './Explore';
import { GrowthPOC } from './GrowthPOC';
import { ProfileRoutes, ProfileRoot } from './Profile';

export const SPA_ROUTES = [
  {
    path: "/",
    Component: Explore,
  },
  {
    path: "growth-poc",
    Component: GrowthPOC,
  },
  {
    path: "account",
    Component: ProfileRoot,
    children: ProfileRoutes,
  },
];

export const SPARouter = ()=>{ 
  const browserRouter = createBrowserRouter(SPA_ROUTES);
  return <RouterProvider router={browserRouter} />;
};

// const getAllRoutes = (routes: RouteObject[]) => {
//   let allRoutes = [];

//   const traverseRoutes = (routeArray: RouteObject[], parentPath = '') => {
//     routeArray.forEach(route => {
//       // Build the full path for the current route
//       const fullPath = `${parentPath}${route.path ? `/${route.path}` : ''}`.replace(/\/+/g, '/');
//       // Add the current route's full path to the list
//       if (route.path) {
//         allRoutes.push(fullPath);
//       }
//       // Recursively handle child routes
//       if (route.children) {
//         traverseRoutes(route.children, fullPath);
//       }
//     });
//   };

//   traverseRoutes(routes);

//   return allRoutes;
// };

// export const ALL_ROUTES = getAllRoutes(SPA_ROUTES);