/**
 * SPA Page
 * 
 */

import React from "react";
import { GoogleSignIn } from "@cenume/auth";
// import { getAccounts, getArticles } from "../../../api"
// import AccountList from "../../../components/Account/AccountList/AccountList";
import { Helmet } from "react-helmet-async";
import { Title, Logo, Button, GenericHeader } from "@cenume/shared-fe";
import { MainPageBannerV1 } from "../../../components/HeroBanners/MainPageBannerV1/MainPageBannerV1";
import ContextWrapper from "../../../components/Utility/ContextWrapper/ContextWrapper";

export const Explore = ()=>{

  const fetchArticles = async ()=>{
    // const res = await getArticles();
    // res.
  }

  React.useEffect(()=>{
    fetchArticles();

  }, [])

  return (<ContextWrapper>
    <GenericHeader>
      <div>nav</div>
      <GoogleSignIn.FloatingGoogleSignInButton />
    </GenericHeader>
    <MainPageBannerV1 />
    <br/>
    
    {/* <Logo style={{filter: "invert(1)"}} width={24} height={24} /> */}
    <Helmet>
      {GoogleSignIn.getGoogleSignInHeaderTags()}
    </Helmet>
  </ContextWrapper>)
}