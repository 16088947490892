/**
 * Display 
 */

import React from "react";
import { Title } from "@cenume/shared-fe"
import { Route } from 'react-router-dom';
import useAuth from "../../../../hooks/useAuth";

export const AccountDisplayPage = ()=>{
  const authStatus = useAuth(true);

  return (<>
    <Title>Account Displays</Title>
    Test Account Displays
  </>)
};
