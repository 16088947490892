/**
 * SPA Page
 * 
 */

import React from "react";
import { getArticles } from "../../../api"
import { Title } from "@cenume/shared-fe"

export const GrowthPOC = ()=>{

  const fetchArticles = async ()=>{
    const res = await getArticles();
    // res.
  }

  React.useEffect(()=>{
    fetchArticles();

  }, [])

  return (<>
    <Title>Growth</Title>
    Test
  </>)
}