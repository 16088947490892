import React from "react";
import useAuth from "../../../hooks/useAuth";
import { ReloginPrompt } from "../ReloginPrompt/ReloginPrompt";
import { FEAuthenticationState } from '../../../hooks/useAuth';

// Todo wrapper component to call auth when rendering page / component wrapped
const AuthWrapper:React.FC<React.PropsWithChildren<{
  accessId: string
}>> = ({ children, accessId }) => {
  const auth = useAuth(true, accessId);
  if (auth.isLoadingAuthenticationState) {
    return <>Loading</>
  }
  if (!auth.isAuthenticated) {
    return <ReloginPrompt authState={auth}></ReloginPrompt>
  }
  return <>
    {children}
  </>
};

export default AuthWrapper;