import React from "react";
import ReactDOM from "react-dom/client";
import { SPARouter } from "./SPA";
import { HelmetProvider } from "react-helmet-async";

const root = ReactDOM.createRoot(document.getElementById('app'));
root.render(
  <HelmetProvider>
    <SPARouter />
  </HelmetProvider>
  , 
);
