/**
 * Todo Segments of edit forms, able to save separately
 */

import React from "react";
import { Title } from "@cenume/shared-fe"
import { Route } from 'react-router-dom';
import { InfoBasic } from "./InfoBasic/InfoBasic";
import { InfoContact } from "./InfoContact/InfoContact";
import { InfoPreference } from "./InfoPreference/InfoPreference";
import { InfoVerification } from "./InfoVerification/InfoPreference";
import AuthWrapper from '../../../../components/Utility/AuthWrapper/AuthWrapper';

export const ManageAccountPage = ()=>{
  return (
    <AuthWrapper accessId="">
      <Title>Manage Account</Title>
      Test Manage Account
      <InfoBasic />
      <InfoContact />
      <InfoPreference />
      <InfoVerification />
    </AuthWrapper>
  );
};
