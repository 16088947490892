import React from 'react';
import { FEAuthenticationState } from '../../../hooks/useAuth';
import { GenericModal } from '@cenume/shared-fe';
import { GoogleSignIn } from '@cenume/auth';

export const ReloginPrompt = (props: {authState: FEAuthenticationState})=>{
  
  return <>
    <GenericModal>
      You need to login 
      <GoogleSignIn.FloatingGoogleSignInButton />
    </GenericModal>
  </>
}