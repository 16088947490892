/**
 * top level wrapper to enable useContext 
 * 
 */
import React from "react";
import { GlobalStateContext } from "../../../context/Context";

const ContextWrapper:React.FC<React.PropsWithChildren<{}>> = ({ 
  children
}) => {
  return <GlobalStateContext.Provider value={{}}>
    {children}
  </GlobalStateContext.Provider>
};

export default ContextWrapper;