import React from 'react';
import styles from './MainPageBannerV1.module.css';

export const MainPageBannerV1 = ()=>{

  return <section className={styles.banner} style={{background: "url('/banner1.jpg') no-repeat center center/cover"}}>
    <div className={styles.bannerContent}>
      <h1>Work in Progress</h1>
      <p>Things are coming - stay tuned.</p>
      {/* <a href="#" className={styles.ctaButton}>Get Started</a> */}
    </div>
  </section>
}
