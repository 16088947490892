
import { AuthService } from '@cenume/auth';
import { useState, useEffect } from 'react';

export type FEAuthenticationState = {
  isAuthenticated: boolean,
  isAuthorized: boolean,
  isLoadingAuthenticationState: boolean,
  userId: string,
  username: string,
  role: string
};

const useAuth = (autoLoad: boolean, accessId?: string)=>{

  const [authStatus, setAuthStatus] = useState<FEAuthenticationState>();
  
  const loadAuthStatus = async ()=>{
    setAuthStatus({...authStatus, isLoadingAuthenticationState: true});
    const res = await AuthService.authenticateWithRefreshToken();
    if (res) {
      const isAuthorized = !accessId || await AuthService.isAuthorizedForAccessId(accessId);
      setAuthStatus({
        isAuthenticated: true,
        isAuthorized: isAuthorized,
        isLoadingAuthenticationState: false,
        userId: "123",
        username: "Test",
        role: "admin"
      });
    } else {
      setAuthStatus({
        isAuthenticated: false,
        isAuthorized: false,
        isLoadingAuthenticationState: false,
        userId: "",
        username: "",
        role: ""
      });
    }
  };

  useEffect(()=>{
    if (autoLoad) loadAuthStatus();
  }, []);

  return {
    ...authStatus,
    loadAuthStatus: loadAuthStatus,
  }
}

export default useAuth;